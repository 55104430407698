@use "../../../assets/sass/helpers/parameters.scss" as *;
@use "../../../assets/sass/helpers/responsive" as *;
@use "../../../assets/sass/helpers/_mixins.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

*:focus {
  outline: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background-color: #fff;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}

.height-application {
  height: 100% !important;
  min-height: 100% !important;
}

* {
  font-family: "Helvetica", Arial, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  vertical-align: middle;
  border: 0;
}

a {
  text-decoration: none;
}

svg:not(:root) {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  margin: 0;
}

a {
  cursor: pointer;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default !important;
  pointer-events: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

[role="button"] {
  cursor: pointer;
}

// DaxlinePro Light
@font-face {
  font-family: "DaxlinePro";
  src:
    local(""),
    url("../fonts/DaxlinePro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

// DaxlinePro ExtraBold
@font-face {
  font-family: "DaxlinePro";
  src:
    local(""),
    url("../fonts/DaxlinePro-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

// DaxlinePro Bold
@font-face {
  font-family: "DaxlinePro";
  src:
    local(""),
    url("../fonts/DaxlinePro-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

// DaxlinePro Medium
@font-face {
  font-family: "DaxlinePro";
  src:
    local(""),
    url("../fonts/DaxlinePro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

// DaxlinePro Thin
@font-face {
  font-family: "DaxlinePro";
  src:
    local(""),
    url("../fonts/DaxlinePro-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

// DaxlinePro Black
@font-face {
  font-family: "DaxlinePro";
  src:
    local(""),
    url("../fonts/DaxlinePro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

// DaxlinePro Regular
@font-face {
  font-family: "DaxlinePro";
  src:
    local(""),
    url("../fonts/DaxlinePro-Regular.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

// Typography
.title-page {
  font-family: "DaxlinePro";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.2px;
}

.title-section {
  font-family: "DaxlinePro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.text-big {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.text-default {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
}

.text-small {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.text-label-form {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
}

.title-paragraph {
  font-family: "DaxlinePro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.text-big-link {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-decoration: underline;
}

.text-default-link {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-decoration: underline;
}

.text-small-link {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration: underline;
}

// svg Colors
.green-success {
  stroke: $success;
  color: $success;
}

.green-success-svg {
  stroke: $success;
  color: $success;

  svg {
    stroke: $success;
    color: $success;
  }
}

.red-error {
  stroke: $error;
  color: $error;

  svg {
    stroke: $error;
    color: $error;
  }
}

.orange-accent {
  svg {
    stroke: $accent;
    color: $accent;
  }
}

.gray-gray1 {
  svg {
    stroke: $gray1;
    color: $gray1;
  }
}

.gray-gray2 {
  svg {
    stroke: $gray2;
    color: $gray2;
  }
}

.blue-primary {
  cursor: pointer;

  svg {
    color: $primary;
  }
}

.white-svg {
  cursor: pointer;

  svg {
    color: $white;
  }
}

.smaller-size {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
}

.small-size {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
}

.medium-size {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}

.regular-size {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.regular-size-avatar-img {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
}

.big-size {
  width: 62px;
  height: 62px;
  min-width: 62px;
  min-height: 62px;
  max-width: 62px;
  max-height: 62px;
}

.bold-text {
  font-weight: bold !important;
}

.cursor-pointer {
  cursor: pointer;
}

.italic-text {
  font-style: italic;
}

.cursor-default {
  cursor: default;
}

// TODO da cambiare con quello vero
.content {
  min-width: 100%;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  &.height100 {
    height: 100%;
  }
}

.page-content-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 24px;

  &.admin-wrapper {
    max-width: 1600px;
  }

  @include respond-to(xs) {
    padding: 0;
  }
}

.contributor-content {
  width: 100%;
  max-width: 980px;
  margin: 32px auto;
}

.full-width {
  width: 100%;
}

.top-tooltip {
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $primary transparent;
  }
}

// Header-dossier select
.header-dossier-select {
  width: 85px;
  min-width: 85px;
  max-width: 85px;

  &.profile {
    margin-top: 10px;

    .ng-value p {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      text-transform: uppercase;
      color: $primary;
    }
  }

  .ng-dropdown-panel {
    min-width: 200px;
    border-radius: 8px;
    overflow: hidden;
    background-color: $gray3;

    .scroll-host {
      div {
        @include layout-column-gap(1px);
      }
    }

    .ng-option {
      padding: 0px;
    }
  }

  .ng-select-container {
    background-color: transparent !important;
    border: 0;
    min-height: 0px;
    height: inherit !important;

    .ng-value-container {
      padding: 0px;
      flex: inherit !important;

      p {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        color: $primary;
      }

      .ng-input {
        top: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        cursor: pointer;
      }
    }

    //// Stili icona chiuso
    //span.ng-arrow-wrapper {
    //  height: 15px;
    //  width: 15px;
    //  min-height: 15px;
    //  max-height: 15px;
    //  max-width: 15px;
    //  min-width: 15px;
    //
    //  span.ng-arrow {
    //    -webkit-mask-repeat: no-repeat;
    //    -webkit-mask-image: url("/assets/img/icons/chevron-down.svg");
    //    -webkit-mask-size: contain;
    //    mask-image: url("/assets/img/icons/chevron-down.svg");
    //    mask-size: contain;
    //    top: 0;
    //    left: 0;
    //    height: 15px;
    //    width: 15px;
    //    min-height: 15px;
    //    max-height: 15px;
    //    max-width: 15px;
    //    min-width: 15px;
    //    background-color: $primary;
    //  }
    //}
  }

  // icona aperto
  &.ng-select-opened > .ng-select-container span.ng-arrow-wrapper {
    height: 15px;
    width: 15px;
    min-height: 15px;
    max-height: 15px;
    max-width: 15px;
    min-width: 15px;

    span.ng-arrow {
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-image: url("/assets/img/icons/chevron-up.svg") !important;
      -webkit-mask-size: contain !important;
      mask-image: url("/assets/img/icons/chevron-up.svg");
      mask-size: contain;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      min-height: 15px;
      max-height: 15px;
      max-width: 15px;
      min-width: 15px;
      background-color: $primary;
    }
  }

  ::ng-deep .ng-arrow {
    display: none !important;
  }

  &.ng-select ::ng-deep .ng-arrow-wrapper {
    height: 16px;
    width: 16px;
    background-image: url("../../img/icons/chevron-down-blue-small.svg");
    background-repeat: no-repeat;
    padding: 0;
  }

  &.ng-select-opened ::ng-deep .ng-arrow-wrapper {
    background-image: url("../../img/icons/chevron-up-blue-small.svg");
  }
}

// Stile per comportamento team-sidebar e layout manager
.wrapper-layout-manager {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: calc(100% - 68px);

  .team-sidebar {
    display: none;
    align-self: stretch;
    background-color: #f9fafc;

    &.open-on-mobile {
      min-width: 250px;

      @include respond-to(xs) {
        display: flex;
        width: 100%;
      }

      @include respond-to(sm) {
        display: flex;
        width: 100%;
      }
    }

    @media only screen and (min-width: 960px) and (max-width: 1099px) {
      display: flex;
      width: 100%;
      max-width: 79px;
    }

    @media only screen and (min-width: 1100px) {
      display: flex;
      width: 100%;
      max-width: 250px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: 350px;
      }
    }
  }

  .router-outlet-wrapper {
    align-self: stretch;
    width: 100%;

    @include respond-to(xs) {
      padding: 0;
    }
  }
}

// contenitore pagina
.main-content-wrapper {
  width: 100%;
  background-color: $soft-gray;
  padding: 60px 40px 40px 40px;
  border-radius: 0px 0px 10px 10px;

  &.admin {
    padding: 40px 40px 40px 40px;
  }

  &.no-sti-padding {
    padding-bottom: 40px !important;
    padding: 0px;
  }

  &.white-background {
    background-color: #fff;
  }

  &.goal-details {
    padding: 32px 40px;
    background: linear-gradient(180deg, #ecf0f8 0%, #f9fafc 100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include layout-column-gap(16px);

    @include respond-to(xs) {
      padding: 32px 16px;
    }

    &.final-evaluation-padding {
      @include layout-column-gap(24px);
    }

    .final-notice-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 16px 24px;
      border-radius: 10px;
      @include normal-shadow;
      width: 100%;
      background-color: #fff;

      .title {
        @extend .text-big;
        color: $primary;
      }

      .descr {
        @extend .text-default;
        color: $gray1;
      }
    }
  }

  @include respond-to(xs) {
    padding: 32px 16px 32px 16px;
  }

  &.sti-label-wrapper {
    position: relative;
    padding: 70px 40px 40px 40px;

    @include respond-to(sm) {
      padding: 85px 40px 40px 40px !important;

      .sti-wrapper {
        top: 30px !important;
      }
    }

    @include respond-to(xs) {
      padding: 85px 0 0 0 !important;

      .sti-wrapper {
        top: 28px !important;
      }
    }

    @include respond-to(xxs) {
      padding: 85px 0 0 0 !important;

      .sti-wrapper {
        top: 38px !important;
      }
    }

    .sti-wrapper {
      position: absolute;
      top: 13px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      left: 0;
      padding: 8px;
      background-color: #fff;
      box-shadow:
        0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04),
        0px 0px 1px rgba(0, 0, 0, 0.04);
      -webkit-box-shadow:
        0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04),
        0px 0px 1px rgba(0, 0, 0, 0.04);
      -moz-box-shadow:
        0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04),
        0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 0px 8px 8px 0px;
      @include layout-row-gap(4px);

      .sti-label {
        font-family: Arial;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #1ec492;
        padding: 2px 5px;
        background: #d4fff2;
        border-radius: 4px;
      }

      .sti-value {
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: $primary;
      }
    }
  }
}

// Classi generiche per i componenti
.box-collection-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  background: linear-gradient(180deg, #ecf0f8 0%, rgba(249, 250, 252, 0) 100%);
  border-radius: 10px;
  margin-top: 24px;

  @include respond-to(xs) {
    border-radius: inherit;
    margin-top: 0;
  }

  &.collaborator {
    padding-right: 0;
  }

  .row-boxes {
    width: 100%;

    &.add-row {
      margin-top: 16px !important;
    }
  }
}

.user-card-collection-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //@include layout-column-gap(16px);

  .row-boxes {
    width: 100%;

    user-card {
      width: 50%;

      &:last-child {
        //margin-bottom: 16px;
      }

      @media screen and (max-width: 1090px) and (min-width: 960px) {
        avatar-img {
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          max-width: 24px;
          max-height: 24px;

          .avatar-wrapper {
            width: 24px !important;
            height: 24px !important;
            min-width: 24px !important;
            min-height: 24px !important;
            max-width: 24px !important;
            max-height: 24px !important;

            p {
              font-family: "Lato";
              font-style: normal;
              font-weight: 600;
              font-size: 10px !important;
              line-height: 16px !important;
            }
          }
        }
      }

      @include respond-to(sm) {
        width: 100%;
      }

      @include respond-to(xs) {
        width: 100%;
      }

      @include respond-to(xxs) {
        width: 100%;
      }
    }
  }
}

// Spinner loader
.loader {
  margin: 50px auto !important;
  background-repeat: no-repeat;
  position: relative;
  -webkit-animation: load3 1s infinite linear;
  animation: load3 1s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &.spinner-big {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    background-image: url(/assets/img/loader/loader-spinner-big.svg);
  }

  &.spinner-medium {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    background-image: url(/assets/img/loader/loader-spinner-medium.svg);
  }

  &.spinner-small {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    background-image: url(/assets/img/loader/loader-spinner-small.svg);
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.accordion-content {
  width: 100%;
  padding: 16px 24px;

  @include respond-to(xs) {
    padding: 16px;
  }

  .gray-background {
    background-color: $soft-gray;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include layout-column-gap(24px);

    @include respond-to(xs) {
      padding: 16px;
    }
  }

  .action-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    @include layout-row-gap(16px);

    @include respond-to(xs) {
      @include layout-row-gap(0);
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      @include layout-column-gap(16px);
    }
  }

  &.text {
    width: 100%;

    p {
      word-break: break-word;
      width: 100%;
      color: $black;
    }
  }

  &.final-evaluation {
    padding: 24px 0;

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .self-title {
        padding: 0 24px;
        @extend .text-default;
        color: $black;
        margin-bottom: 11px;
      }
    }
  }

  .final-evaluation-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px;
    width: 100%;
    background-color: #f5f6fc;

    &.clickable {
      cursor: pointer;
    }

    &.white-background {
      background-color: #fff;
    }

    &.divisor {
      border-bottom: 1px solid #d3d3d3;
    }

    @include respond-to(xs) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include layout-column-gap(8px);
    }

    @include respond-to(xxs) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include layout-column-gap(4px);
    }

    .left-side {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      @include layout-row-gap(10px);

      .user-name {
        font-family: "Lato";
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: underline;
        color: $black;
      }

      .evaluation {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        p {
          font-family: "Lato";
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 30px;
          text-transform: uppercase;
          color: $primary;
          margin-right: 4px;
        }

        svg-icon {
          width: 25px;
          height: 25px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .compiled {
      font-family: "Lato";
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: $gray2;
    }
  }

  .dashboard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border: 1px solid #e3e3e3;
    // height: 275px;
    // min-height: 275px;
    // max-height: 275px;

    &.big {
      //height: 393px;
      //min-height: 393px;
      //max-height: 393px;
    }

    @media screen and (max-width: 960px) {
      flex-wrap: wrap;

      .right {
        max-width: 100% !important;
      }
    }

    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;
      background-color: #fff;
      flex-grow: 1;
      height: 100%;
      // min-height: 350px;
      width: 100%;
      @include layout-column-gap(16px);
      align-self: stretch;
      flex-grow: 1;

      &.right {
        background-color: #f5f6f8;
        max-width: 50%;
      }

      &.card-section-height {
        height: 312px;

        @include respond-to(xxs) {
          height: 312px;
        }
      }

      .title-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        @include layout-row-gap(8px);

        .title {
          font-family: "DaxlinePro";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          color: $primary;
        }

        .icon {
          cursor: pointer;
          width: 19px;
          height: 19px;
          color: $primary;
        }
      }

      .midterm-card-container {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        max-width: 100%;
        width: 288px;
        @include layout-row-gap(8px);

        .card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 12px 8px;
          background: #ffffff;
          border: 1px solid rgba(23, 35, 140, 0.26);
          border-radius: 5px;
          @include small-shadow;
          max-width: 90px;
          min-width: 90px;
          height: 136px;

          .icon {
            width: 60px;
            height: 60px;
          }

          .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 10px;

            p {
              width: 100%;
              font-family: "Lato";
              font-style: normal;
              font-weight: 600;
              font-size: 9px;
              line-height: 18px;
              text-align: center;
              text-transform: uppercase;
              color: $primary;
            }

            .value {
              color: $black;
              font-size: 20px;
            }
          }
        }
      }

      .more-graph-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @include layout-row-gap(16px);

        p {
          color: #767676;
        }

        .number {
          font-family: "DaxlinePro";
          font-style: normal;
          font-weight: 600;
          font-size: 34px;
          line-height: 34px;

          &.primary {
            color: $primary;
          }
        }

        .text {
          font-family: Arial;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 13px;
        }
      }
    }
  }
}

// Codice modale
.modal-code-wrapper {
  // display: none;
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 8px;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: $primary;
}

// Contenitore accordion sfide
.challenge-accordion-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  padding-top: 24px;
  @include layout-column-gap(24px);

  @include respond-to(xs) {
    padding: 0 16px;
    padding-top: 16px;
    @include layout-column-gap(16px);
  }

  .development-action-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background: linear-gradient(180deg, #ecf0f8 0%, #f9fafc 100%);
    border-radius: 10px;
    align-self: stretch;
    flex-grow: 0;

    .box-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      width: 100%;
      @include layout-row-gap(32px);

      .img-content {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        max-width: 80px;
        max-height: 80px;
        @include medium-shadow;
        border-radius: 80px;
        background-position: center;
        background-size: cover;
      }

      .development-box-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .title {
          @extend .title-section;
          font-weight: bold;
          display: flex;
          align-items: center;
          color: $primary;
        }

        .description {
          @extend .text-big;
          color: $primary;
          width: 100%;
        }
      }
    }
  }

  .person-title-text {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: $primary;
  }

  .rule-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg-icon {
      margin-right: 10px;
    }

    p {
      font-family: "Lato";
      font-weight: 900;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: $primary;
    }
  }

  .valutation-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .final-evaluation-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 24px;
      min-height: 66px;
      width: 100%;
      background-color: #f5f6fc;

      &.white-background {
        background-color: #fff;
      }

      @include respond-to(xs) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        @include layout-column-gap(8px);
      }

      @include respond-to(xxs) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        @include layout-column-gap(4px);
      }

      .left-side {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        @include layout-row-gap(10px);

        .user-name {
          cursor: pointer;
          font-family: "Lato";
          font-size: 14px;
          line-height: 22px;
          text-decoration-line: underline;
          color: $black;
        }

        .evaluation {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          p {
            font-family: "Lato";
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 30px;
            text-transform: uppercase;
            color: $primary;
            margin-right: 4px;
          }

          svg-icon {
            width: 25px;
            height: 25px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .compiled {
        font-family: "Lato";
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: $gray2;
      }
    }
  }
}

// Componente mini-card
.mini-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 6px;
  box-sizing: border-box;
  border-radius: 3px;
  flex-grow: 0;

  margin-top: 2px;
  margin-bottom: 2px;

  &.default {
    border: 1px solid $gray1;

    p {
      color: $gray1;
    }
  }

  &.blue {
    border: 1px solid $primary;

    p {
      color: $primary;
    }
  }

  &.accent {
    border: 1px solid $accent;

    p {
      color: $accent;
    }
  }

  p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
}

//guidelines
.guidelines {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;

  &.height100 {
    height: 100%;
  }

  svg-icon {
    width: 100%;
    @include fixed-height(100px);
    text-align: center;
  }

  .text-wrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include layout-column-gap(10px);

    div {
      width: 100%;
    }

    .title {
      width: 100%;
      font-family: "DaxlinePro";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.2px;
      color: $primary;
      margin-top: 16px;
      margin-bottom: 0px;
    }

    h1 {
      width: 100%;
    }

    p {
      width: 100%;
      text-align: left;
      font-family: "Lato";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $gray1;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      margin: 16px 0;
    }
  }
}

.loader-container {
  width: 100%;
  min-height: 300px;
}

// classe modale per upload
.file-upload-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include layout-column-gap(24px);

  .title {
    width: 100%;
    @extend .title-page;
    color: $primary;
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d3d3d3;

    &.center {
      text-align: center;
    }
  }
}

.align-center {
  text-align: center;
}

.primary-text-color {
  color: $primary;
}

// linea orizzontale usata un po' ovunque
.divisor-line {
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
}

// Errore calendario dietro a modale
.cdk-overlay-container {
  z-index: 99999999;
}

// modale richiesta peer feedback
.peer-feedback-modal-request {
  width: 100%;

  .title-container {
    @extend .title-page;
    color: $primary;
    text-align: center;
    width: 100%;
    padding: 24px 0;
    border-bottom: 1px solid #d3d3d3;
  }

  .confirm-request-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 0;
    @include layout-column-gap(8px);

    .section {
      display: column;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      @include layout-column-gap(8px);

      .title {
        font-family: "DaxlinePro";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: $primary;
      }

      .content {
        font-family: "Lato";
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: $gray1;
      }
    }

    .users {
      .content {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        @include layout-row-gap(16px);

        .mini-user-card {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          @include layout-row-gap(8px);
          margin-bottom: 8px;

          .name {
            font-family: "Lato";
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: $black;
          }
        }
      }
    }
  }
}

// classe condivisa per la gestione delle modali mid term
.modal-recap-container {
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include layout-column-gap(16px);

  mid-term-recap {
    width: 100%;
  }
}

.impersonate-page-container-height {
  .sidenav-information {
    height: calc(100vh - 50px);

    @include respond-to(xs) {
      width: inherit;
      height: inherit;
      max-width: 100%;
    }

    @include respond-to(sm) {
      width: inherit;
      height: inherit;
      max-width: 100%;
    }
  }

  .main-content {
    height: calc(100vh - 50px);

    @include respond-to(xs) {
      height: 100%;
    }

    @include respond-to(sm) {
      height: 100%;
    }
  }

  // @include respond-to(xs) {
  //   width: inherit;
  //   height: inherit;
  //   max-width: 100%;
  // }
  //
  // @include respond-to(sm) {
  //   width: inherit;
  //   height: inherit;
  //   max-width: 100%;
  // }
}

.divisor-line {
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
}

.final-evaluation-modal-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include layout-column-gap(24px);

  .modal-title {
    @extend .title-page;
    color: $primary;
  }

  .observation-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    @include layout-column-gap(8px);

    .title {
      @extend .title-paragraph;
      color: $primary;
    }

    .descr {
      @extend .text-big;
      color: $gray1;
    }
  }
}

.evaluation-guide-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @include layout-column-gap(24px);

  .title {
    text-align: center;
    @extend .title-page;
    color: $primary;
  }

  .subtitle {
    @extend .title-paragraph;
    color: $primary;
  }

  .section-box-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @include layout-column-gap(16px);

    .box-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 16px;
      @include layout-row-gap(18px);

      img {
        max-width: 126px;
      }

      &.achieved {
        background: #fcadae;
        border-radius: 8px;
      }

      &.not-achieved {
        background: #f1f6f7;
        border-radius: 8px;
      }

      &.exceeded {
        background: linear-gradient(
          180deg,
          #3e8dd8 0.1%,
          #499ae4 9.3%,
          #52a1e7 17.76%,
          #5da8ee 32.22%,
          #5ca8ed 49.22%,
          #55a2e6 63.25%,
          #4893de 76.92%,
          #3d88d5 85.36%,
          #317bc7 94.04%,
          #1f6fb7 100.1%
        );
        border-radius: 8px;
      }

      .description-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .descr-title {
          font-family: "Lato";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.2px;
          color: $primary;
        }

        .descr-text {
          @extend .text-small;
          color: $primary;
        }
      }
    }
  }
}

.margin-top10 {
  margin-top: 10px;
}

.performance-level-mark-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include layout-column-gap(10px);

  .title-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }

  .mark-text {
    @extend .text-small;
    color: $gray1;
  }
}

.center {
  text-align: center;
}

// Modale conferma
.modal-title {
  width: 100%;
  @extend .title-page;
  color: $primary;
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #d3d3d3;

  &.center {
    text-align: center;
  }
}

// @font-face {
//   font-family: "DXIcons";
//   src: local("DevExtreme Generic Icons"), local("devextreme_generic_icons"), url("icons/dxicons.woff2") format("woff2"),
//     url("icons/dxicons.woff") format("woff"), url("icons/dxicons.ttf") format("truetype");
//   font-weight: 400;
//   font-style: normal;
// }

// Toaster per feedback salvataggio valutazione competenze
#snackbar,
#snackbar-delete {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
  background: #25bb48;
  border: 1px solid #25bb48;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  p {
    font-family: "Roboto", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
}

#snackbar.show,
#snackbar-delete.show {
  visibility: visible;
  -webkit-animation:
    fadein 0.5s,
    fadeout 0.5s 2.5s;
  animation:
    fadein 0.5s,
    fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.objective-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  min-height: 350px;

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #17238c;
    padding-bottom: 24px;
    border-bottom: 1px solid #d3d3d3;
    width: 100%;
  }

  .section-container {
    width: 100%;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    overflow-y: auto;

    .section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      width: 100%;

      h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: $black;
      }

      p {
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2px;
        color: #6e6f71;
      }
    }
  }
}

// Box obiettivi cascading
.cascading-objective-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  background: linear-gradient(180deg, #1f376a 0%, #274892 100%);
  border-radius: 10px;
  min-height: 56px;

  .show-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 3px;

    .show-icon {
      width: 20px;
      height: 20px;
      color: white;
    }
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: $white;
  }
}

// Messaggio interno a child content
.share-status-message {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: $black;
}
