@import "../pages/general";
@import "../helpers/responsive.scss";
@import "../helpers/parameters.scss";
@import "../helpers/mixins.scss";

// Accordion Operation
.accordion-content {
  @include layout-column-gap(24px);

  .file-upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include layout-column-gap(24px);
    .info-content {
      display: flex;
      flex-direction: row;
      width: 100%;
      text-align: center;
      @extend .text-default;
      color: $gray1;
    }

    .uploader-component {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px;
      background-color: #f2f2f2;
      border-radius: 10px;
      @include layout-column-gap(24px);
      min-width: 300px;
      width: 100%;
      max-width: 400px;

      .text-content {
        font-family: "DaxlinePro";
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $gray1;
      }
    }
  }
}

// box feedback dal manager accordion C.A.R.E
.feedback-message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: #edf1f8;
  border-radius: 4px;
  @include layout-row-gap(10px);

  .circle-div {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $secondary;
  }

  p {
    font-family: "Lato";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $secondary;
  }
}

// box info accordion C.A.R.E
.message-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  background: linear-gradient(180deg, #ecf0f8 0%, #f9fafc 100%);
  border-radius: 10px;
  padding: 24px;

  @include respond-to(xs) {
    flex-direction: column;
  }

  .section-icon {
    width: 164px;
    height: 130px;
    min-width: 164px;
    min-height: 130px;
    max-width: 164px;
    max-height: 130px;
  }

  .message-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: $primary;
    }

    .descr {
      font-family: "Lato";
      font-size: 16px;
      line-height: 24px;
      color: $primary;
      width: 100%;
    }
  }
}

// Contenitore tag competenze
.competence-level-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 24px;
  @include layout-row-gap(24px);

  @include respond-to(xs) {
    padding-bottom: 16px;
  }

  &.last-container {
    border-bottom: none;
  }

  .level-image {
    height: 62px;
    width: 62px;
    min-height: 62px;
    min-width: 62px;
    max-height: 62px;
    max-width: 62px;
  }

  .level-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include layout-column-gap(24px);

    .level-title {
      font-family: "Lato";
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      text-transform: uppercase;
      color: $primary;
    }

    .tag-competence-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      @include layout-column-gap(16px);
      @include layout-row-gap(10px);
    }
  }
}

// Box grigio con informazioni sulla competenze
.description-wrapper {
  width: 100%;
  background: #f2f2f2;
  border-radius: 10px;
  padding: 16px;
  @include layout-column-gap(16px);

  &.min-height100 {
    min-height: 100px;
  }

  .care-content {
    h3 {
      font-family: "Lato";
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: $primary;
    }

    ul {
      padding-inline-start: 16px;

      li {
        font-family: "Lato";
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #737373;
      }
    }
  }

  .section-wrapper {
    .descr-title {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $primary;
      text-transform: uppercase;
    }

    .descr-subtitle {
      font-family: "Lato";
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #737373;
    }

    .descr-info {
      font-family: "Lato";
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: $primary;
    }

    .descr-content {
      font-family: "Lato";
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #737373;

      .align-list {
        margin-left: 15px;
      }
    }
  }
}

// contenitore modale di dettaglio e feedback competenze, modifica ecc.
.competence-detail-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include layout-column-gap(24px);
  width: 100%;

  .title {
    @extend .title-page;
    color: $primary;
    margin-top: 24px;
  }
}

.file-upload-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include layout-column-gap(24px);

  .title {
    width: 100%;
    @extend .title-page;
    color: $primary;
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d3d3d3;
  }
}
