@use "./parameters" as *;

/* MEDIA QUERIES */
@mixin respond-to($media) {
  @if $media==desktop-xl {
    @media only screen and (min-width: $media-lg) {
      @content;
    }
  } @else if $media==gt-xxs {
    @media only screen and (min-width: $media-xxs) {
      @content;
    }
  } @else if $media==xs {
    @media only screen and (max-width: $media-xs - 1) {
      @content;
    }
  } @else if $media==gt-xs {
    @media only screen and (min-width: $media-xs) {
      @content;
    }
  } @else if $media==sm {
    @media only screen and (min-width: $media-xs) and (max-width: $media-sm - 1) {
      @content;
    }
  } @else if $media==gt-sm {
    @media only screen and (min-width: $media-sm) {
      @content;
    }
  } @else if $media==md {
    @media only screen and (min-width: $media-sm) and (max-width: $media-md - 1) {
      @content;
    }
  } @else if $media==gt-md {
    @media only screen and (min-width: $media-md) {
      @content;
    }
  } @else if $media==lg {
    @media only screen and (min-width: $media-md) and (max-width: $media-lg - 1) {
      @content;
    }
  } @else if $media==gt-lg {
    @media only screen and (min-width: $media-lg) {
      @content;
    }
  } @else if $media==xl {
    @media only screen and (min-width: $media-lg) {
      @content;
    }
  } @else if $media==xxs {
    @media only screen and (max-width: $media-xxs - 1) {
      @content;
    }
  }
}
